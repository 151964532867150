import React, { Component } from 'react';
import { inject, observer } from "mobx-react";

import * as utilities from '../../utilities/formats';
import { IEView } from 'react-device-detect';

const ListProduct = inject("stores") (
    observer (
        class ListProduct extends Component {

            constructor(props) {
                super(props);
                this.urlAPI = process.env.REACT_APP_URL_API;

                this.storeAuth = this.props.stores.storeAuth;
                this.storeCarts = this.props.stores.storeCarts;

                this.state = {
                    products: []
                }
            }

            componentDidMount = async() => {
                let amount = 0;

                const idx = this.storeCarts.cart.findIndex(item => item.sku === this.props.sku);
                if (idx !== -1) {
                    amount = this.storeCarts.cart[idx].amount;
                }

                this.setState({
                    type: this.props.type,
                    idx: idx,
                    amount: amount
                })
            }

            componentDidUpdate = async(prevProps) => {
                
            }

            handleAddProduct = async() => {
                if (this.storeAuth.isLoggedin) {
                    const productAdded = await this.storeCarts.addToCart('account', this.storeAuth.cartId, this.props.product, 1)
                } else {
                    const productAdded = await this.storeCarts.addToCart('guest', this.storeAuth.cartId, this.props.product, 1)                   
                }

                this.storeCarts.getCartAmount(this.storeAuth.cartId);
                
                // let idx;
                // if (this.storeCarts.cart.length > 0) {
                //     idx = this.storeCarts.cart.findIndex(item => item.sku === this.props.sku);
                // } else {
                //     idx = 0;
                // }
 
                this.setState({
                    type: this.props.type,
                    amount: 1
                })
            }

            render() {
                return (
                    <div className="list-products__listitem">
                        <div>
                            <span 
                                className="product__header"
                            >
                                {this.props.product.displayValue}
                            </span>
                            <ul className="product__summary">
                                {this.props.product.description &&
                                    <li className="product__description">
                                        <span 
                                            dangerouslySetInnerHTML={{ __html: this.props.product.description}}
                                        />
                                    </li>
                                }
                            </ul>
                        </div>
                        <div className="product__summary">
                            <ul className="product__summary--list">
                                <li className="product__specs">
                                    <div className="product__specs--price">
                                        <span 
                                            className="product__subheader"
                                        >
                                            Prijs:
                                        </span>
                                        <span className="product__price">
                                            {utilities.displayPrice(this.props.product.price)}
                                        </span>
                                    </div>
                                    
                                    <div
                                        className="product__specs--ingredients"
                                    >
                                        <span 
                                            className="product__subheader"
                                        >
                                            Bevat:
                                        </span>
                                        <span>
                                            {this.props.product.ingredients}
                                        </span>
                                    </div>
                                    
                                    <div 
                                        className="product__specs--contents"
                                    >
                                        <span 
                                            className="product__subheader"
                                        >
                                            Inhoud:
                                        </span>
                                        <span>
                                            {this.props.product.contents}&nbsp;{this.props.product.unit}
                                        </span>
                                    </div>

                                    <div 
                                        className="product__specs--packaging"
                                    >
                                        <span 
                                            className="product__subheader"
                                        >
                                            Verpakking:
                                        </span>
                                        <img 
                                            src={this.urlAPI + "public/images/icons/"+ this.props.product.container + ".png"} 
                                        />
                                     </div>
                                </li>
                            </ul>
                        </div>

                        <div className="product__action-buttons">
                            {this.state.amount > 0 
                            ?    <button
                                    className = "btn btn--primary --inactive"
                                >
                                    Voeg toe aan bestelling
                                </button>
                            :   <button
                                    className = "btn btn--primary --link"
                                    onClick = {() => this.handleAddProduct()}
                                >
                                Voeg toe aan bestelling
                            </button>
                            }
                        </div>
                            
                        {this.state.amount > 0 &&
                            <div>
                                Momenteel {this.state.amount} in uw winkelmandje.<br/>
                                U kunt het aantal aanpassen in uw winkelmandje.
                            </div>
                        }
                    </div>
                       
                )
            }
        }
    )
)

export default ListProduct;

