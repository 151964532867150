import StoreAuth from './store-auth';
import StoreGeneral from './store-general';
import StoreUi from './store-ui';
import StoreContent from './store-content';
import StorePayments from './store-payments';
import StoreProducts from './store-products';
import StoreForms from './store-forms';
import StoreCarts from './store-carts';
import StoreShipping from './store-shipping';
import StoreUsers from './store-users';
import StoreOrders from './store-orders';
import StoreVAT from './store-vat';

class StoreRoot {

    constructor() {  
        this.storeAuth = new StoreAuth(this);  
        this.storeGeneral = new StoreGeneral(this);
        this.storeUi = new StoreUi(this);
        this.storeContent = new StoreContent(this);
        this.storePayments = new StorePayments(this);
        this.storeProducts = new StoreProducts(this);
        this.storeForms = new StoreForms(this);
        this.storeCarts = new StoreCarts(this);
        this.storeShipping = new StoreShipping(this);
        this.storeUsers = new StoreUsers(this);
        this.storeOrders = new StoreOrders(this);
        this.storeVAT = new StoreVAT(this);
    }
    
}

export default StoreRoot;