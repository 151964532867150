import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import { withRouter } from "react-router-dom";
import i18n from "i18next";

import Template from '../../templates/template';

import FormProfile from '../../components/forms/form-profile'
import FormAddress from '../../components/forms/form-address'
import CartItems from '../../components/cart/cart-items';
import FormPets from '../../components/forms/form-pets';

const CartReview = inject("stores") (
    observer (
        class CartReview extends Component {

            constructor(props) {
                super(props);

                this.storeAuth = this.props.stores.storeAuth;
                this.storeCarts = this.props.stores.storeCarts;
                this.storeContent = this.props.stores.storeContent;
                this.storeUsers = this.props.stores.storeUsers;
                this.storeOrders = this.props.stores.storeOrders;

                this.state = {
                    btnProfile: false,
                    btnAddress: false
                }
            }

            componentDidMount = async () => {
                window.scroll(0,0);
                this.getCart();

                let idx = 43;
                const contentOrdering = await this.storeContent.getContentBlock('pageblocks', idx);
                idx = 41;
                const contentShipping = await this.storeContent.getContentBlock('pageblocks', idx);
                
                this.setState({
                    contentOrdering: contentOrdering,
                    contentShipping: contentShipping
                })
            }

            componentDidUpdate = async (prevProps) => {
            }

            getCart = async() => {
                const id = this.storeAuth.getCartId();
                const cart = await this.storeCarts.getCart(id);
               
                this.setState({
                    cartId: id,
                    cart: cart
                })
            }

            gotoPage = (url) => {
                this.props.history.push(url);
            }

            handleBtnAddress = (value) => {
                this.setState({
                    btnAddress: value
                })
            }

            handleBtnProfile = (value) => {
                this.setState({
                    btnProfile: value
                })
            }

            submitOrder = async() => {
                let url;
                const result = await this.storeOrders.submitOrder(this.state.cartId);

                if (result === 'success') {
                    url = '/payment-result';
                    this.props.history.push(url);
                } else {
                    url = '/payment-error';
                    this.props.history.push(url);
                }
            }

            render() {
                return (
                    <Template
                        title = {"Uw gegevens & betaling"}
                    >
                        <div className="page-content page-content--review">
                            <div className="segment--content">
                                <div className="segment--content__columns">
                                    <div className="segment--content__column">                                       
                                        <div 
                                            className="content__header"
                                            dangerouslySetInnerHTML={{ __html: "Vul uw gegevens in"}}
                                        />
                                        <div 
                                            className="content__text" 
                                        >
                                            <div className="content--form">
                                                <FormProfile
                                                    handleBtnProfile = {this.handleBtnProfile}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="segment--content__column">  
                                        <div 
                                            className="content__header"
                                            dangerouslySetInnerHTML={{ __html: "Vul uw adresgegevens in"}}
                                        /> 
                                        <div 
                                            className="content__text" 
                                        >
                                            <div className="content--form">
                                                <FormAddress
                                                    handleBtnAddress = {this.handleBtnAddress}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="segment--content__columns">
                                    <div className="segment--content__column"> 
                                        <div 
                                            className="content__header"
                                            dangerouslySetInnerHTML={{ __html: "Mijn huisdier(en)"}}
                                        />
                                    </div>
                                </div>
                                
                                <FormPets

                                />
                               
                            </div>

                            <div className="segment--content">
                                <div className="segment--content__columns">
                                    <div className="segment--content__column">  
                                        <div 
                                            className="content__header"
                                            dangerouslySetInnerHTML={{ __html: "Controleer uw bestelling"}}
                                        />
                                        <div className="container-cart__content">
                                            <CartItems 
                                                cart = {this.state.cart}
                                                editable = {false}
                                            />
                                            <div className="cart-actions">
                                                <Link to="/cart">Wijzig je bestelling</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="segment--content">
                                <div className="segment--content__columns">
                                    <div className="segment--content__column">  
                                        {this.state.contentOrdering && this.state.contentOrdering.title && (
                                            <div 
                                                className="content__header"
                                                dangerouslySetInnerHTML={{ __html: this.state.contentOrdering.title.rendered}}
                                            />
                                            )
                                        }
                                        {this.state.contentOrdering && this.state.contentOrdering.content &&
                                            <div 
                                                className="content__text" 
                                                dangerouslySetInnerHTML={{ __html: this.state.contentOrdering.content.rendered}}
                                            />
                                        }

                                        {(this.state.btnProfile && this.state.btnAddress)
                                        ?   <div className="form-row form-row--buttons --order">
                                                <button 
                                                    className="btn btn--primary --link --active"
                                                    onClick={() => this.submitOrder()}
                                                >
                                                    Verstuur uw bestelling
                                                </button>
                                            </div>
                                        :   <div className="form-row form-row--buttons --order">
                                                <button 
                                                    className="btn btn--primary --disabled"
                                                >
                                                    Verstuur uw bestelling
                                                </button>
                                            </div>
                                        }

                                        {this.state.error &&
                                            <div className="form-row form-row--error">
                                                <p>{i18n.t("errors.error_send")}</p>
                                            </div>
                                        }
                                        
                                        {this.state.feedback &&
                                            <div 
                                                className="form-row form-row--feedback"
                                                dangerouslySetInnerHTML={{ __html: i18n.t("forms.feedback.success")}} 
                                            />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Template>
                )
            }
        }
    )
)

export default withRouter(CartReview);

