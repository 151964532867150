import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router-dom';

const NavigationMain = inject("stores") (
    observer (
        class NavigationMain extends Component {

            constructor(props) {
                super(props);
                this.urlAPI = process.env.REACT_APP_URL_API;

                this.storeUi = this.props.stores.storeUi;
                this.storeCarts = this.props.stores.storeCarts;

                this.state = {
                    cartAmount: 0
                }
            }

            componentDidMount = async () => {
                window.addEventListener('scroll', this.isSticky);

                this.setState({
                    cartAmount: this.props.cartAmount
                })
            }

            componentDidUpdate = async(prevProps) => {
                if (prevProps.cartAmount !== this.props.cartAmount) {
                    this.setState({
                        cartAmount: this.props.cartAmount
                    })
                }
            }

            gotoPage = (url) => {
                this.props.history.push(url);
            }

            isSticky = () => {
                const navEl = document.querySelector('.header-content__navigation--main');
                const navCart = document.querySelector('.header-content__navigation--cart');
                const scrollTop = window.scrollY;

                const posTop = this.storeUi.heightBranding;

                if (scrollTop >= posTop) {
                    navEl.classList.add('--sticky');
                    navCart.classList.add('--sticky');
                } else {
                    navEl.classList.remove('--sticky');
                    navCart.classList.remove('--sticky');
                }
            }

            //const margin_bottom = this.storeUi.app_interface.height_footer;

            //<div style={{marginBottom: margin_bottom}}>

            render() {
                return (
                    <React.Fragment>
                    <div className="header-content header-content__navigation--main">
                        <ul className="navigation--main__list">
                            <li 
                                className="navigation--main__listitem navigation--main__listitem--home --link"
                                onClick={() => this.gotoPage('/')}
                            >
                                <span>Home</span>
                            </li>
                            <li 
                                className="navigation--main__listitem navigation--main__listitem--products --link"
                                onClick={() => this.gotoPage('/products')}
                            >
                                <span>Producten</span>
                            </li>
                            <li 
                                className="navigation--main__listitem navigation--main__listitem--about --link"
                                onClick={() => this.gotoPage('/about')}
                            >
                                <span>Over Krachtkruid</span>
                            </li>
                            <li 
                                className="navigation--main__listitem navigation--main__listitem--about --link"
                                onClick={() => this.gotoPage('/contact')}
                            >
                                <span>Contact</span>
                            </li>
                            <li
                                className="navigation--main__listitem --link"
                                onClick={() => this.gotoPage('/cart')}
                            >
                                <ul className="navigation--main__cart">
                                    <li>
                                        <img src={this.urlAPI + "public/images/icons/ic-cart.png"} />
                                        <span
                                            className="navigation--main__cart-counter"
                                        >
                                            {this.state.cartAmount}
                                        </span>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <div className="header-content header-content__navigation--cart">
                        
                    </div>
                </React.Fragment>
                )
            }
        }
    )
)

export default withRouter(NavigationMain);

