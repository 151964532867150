import React, { Component } from 'react';
import { inject, observer } from "mobx-react";

import * as utilities from '../../utilities/formats';
import { IEView } from 'react-device-detect';
import ListProduct from './list-product';

const ListProducts = inject("stores") (
    observer (
        class ListProducts extends Component {

            constructor(props) {
                super(props);
                this.urlAPI = process.env.REACT_APP_URL_API;

                this.storeCarts = this.props.stores.storeCarts;
                
                this.state = {
                    products: []
                }
            }

            componentDidMount = () => {
                // this.storeCarts.getCart(1);

                this.setState({
                    type: this.props.type
                })
            }

            componentDidUpdate = async (prevProps) => {
                if (prevProps.products !== this.props.products) {
                    let listProducts = [];
                    
                    this.props.products.map((product) => {
                        if (product.type === this.state.type) {
                            listProducts.push(product);
                        }
                    })

                    this.setState({
                        products: listProducts
                    })
                    
                }
            }

            render() {
                return (
                    <div className="list-products__list">
                        {this.state.products.map((product) => (
                            <ListProduct
                                product = {product}
                                sku = {product.sku}
                            />
                        ))}
                    </div>
                )
            }
        }
    )
)

export default ListProducts;

