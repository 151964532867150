import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import queryString from 'query-string';

import Template from '../../templates/template';

const PaymentResult = inject("stores") (
    observer (
        class PaymentResult extends Component {
            constructor(props) {
                super(props);
                this.props = props;
                this.env = process.env.REACT_APP_ENV;
                this.urlAPI = process.env.REACT_APP_URL_API;

                this.storeAuth = this.props.stores.storeAuth;
                this.storeContent = this.props.stores.storeContent;
                this.storeCarts = this.props.stores.storeCarts;
                this.storeOrders = this.props.stores.storeOrders;

                this.state = {

                }                          
            }

            componentDidMount = async() => {
                window.scroll(0,0);

                let idx = 44;
                const contentOrdering = await this.storeContent.getContentBlock('pageblocks', idx);
                
                this.setCart();

                this.setState({
                    contentOrdering: contentOrdering
                })
            }

            componentDidUpdate = async() => {
                this.getCart();
            }

            handleScroll = () => {
                window.scrollTo(0, 0);
            }

            gotoPage = (url) => {
                this.props.history.push(url);
            }

            setCart = async() => {
                const cartId = 0;
                this.storeCarts.setCartAmount(0);
                this.storeAuth.deleteCartId();
            }

            render() {
                return (
                    <Template
                        active = {'payment'}
                        title = {this.state.contentOrdering && this.state.contentOrdering.title.rendered}
                    >                       
                        <div className="page-content page-content--cart">
                            <div className="segment--content">
                                <div className="segment--content__columns">
                                    <div className="segment--content__column">
                                        {/* {this.state.contentOrdering && this.state.contentOrdering.title && (
                                            <div 
                                                className="content__header"
                                                dangerouslySetInnerHTML={{ __html: this.state.contentOrdering.title.rendered}}
                                            />
                                            )
                                        } */}
                                        {this.state.contentOrdering && this.state.contentOrdering.content &&
                                            <div 
                                                className="content__text" 
                                                dangerouslySetInnerHTML={{ __html: this.state.contentOrdering.content.rendered}}
                                            />
                                        }

                                        <p>Het totaalbedrag is <strong>€ {this.storeOrders.orderFb.total}</strong><br/>
                                        Uw bestelling heeft ordernummer: <strong>O-{this.storeOrders.orderFb.orderId}</strong>
                                        </p>
                                        <p>U kunt dit overmaken op Rabobankrekening:<br/>
                                        NL29RABO 0352 2576 52<br/>
                                        T.n.v Krachtkruid<br/>
                                        Graag met vermelding van het bestelnummer: O-{this.storeOrders.orderFb.orderId}<br/>
                                        </p>
                                        <p>Normaal gesproken kunt u uw bestelling binnen een paar werkdagen na betaling op het bezorgadres verwachten.</p>
                                    </div>
                                </div>

                                <div className="segment--content__columns">
                                    <div className="segment--content__column">
                                        <div className="form-row form-row--buttons --order">
                                            <button 
                                                className="btn btn--primary"
                                                onClick={() => this.gotoPage('/products')}
                                            >
                                                Verder winkelen
                                            </button>
                                        </div>
                                    </div>
                                </div>
                             </div>
                         </div>
                    </Template>
                );
            }
        }
    )
)

export default PaymentResult;
