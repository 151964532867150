import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router-dom';

import Template from '../../templates/template';

import CartOverview from '../../components/cart/cart-overview';
import CartShipping from '../../components/cart/cart-shipping';

const Cart = inject("stores") (
    observer (
        class Cart extends Component {

            constructor(props) {
                super(props);
                this.storeAuth = this.props.stores.storeAuth;
                this.storeCarts = this.props.stores.storeCarts;

                this.state = {
                    btnActive: false,
                    btnClass: '--inactive'
                }
            }

            componentDidMount = async() => {
                window.scroll(0,0);
            }

            componentDidUpdate = async (prevProps) => {

            }

            gotoPage = (url) => {
                this.props.history.push(url);
            }

            render() {
                return (
                    <Template
                        title = {"Uw winkelwagen"}
                    >
                        <div className="page-content page-content--cart">
                            <div className="segment--content">
                                <div className="segment--content__columns">
                                    <div className="segment--content__column">
                                        <CartOverview 
                                        
                                        />
                                                                            
                                        <div className="form-row form-row--buttons --order">
                                            <button 
                                                className="btn btn--secundary --link"
                                                onClick={() => this.gotoPage('/products')}
                                            >
                                                Terug naar producten
                                            </button>
                                            {(this.storeCarts.cart && this.storeCarts.cart.length !== 0)
                                            ?   <button 
                                                    className={"btn btn--primary --link --active"}
                                                    onClick={() => this.gotoPage('/review')}
                                                >
                                                    Bestel deze artikelen
                                                </button>
                                            :   <button 
                                                    className={"btn btn--primary --inactive"}
                                                >
                                                    Bestel deze artikelen
                                                </button>
                                            }
                                        </div>                                        
                                    </div>
                                </div>
                            </div>

                            <CartShipping />
                        </div>
                    </Template>
                )
            }
        }
    )
)

export default Cart;

