import React, { Component } from 'react';
import { inject, observer } from "mobx-react"

const CartShipping = inject("stores") (
    observer (
        class CartShipping extends Component {

            constructor(props) {
                super();
                this.props = props;
                this.state = {
                   
                }

                this.storeContent = this.props.stores.storeContent;
            }

            componentDidMount = async() => {
                window.scroll(0,0);

                const idx = 41;
                const contentShipping = await this.storeContent.getContentBlock('pageblocks', idx);
                
                this.setState({
                    contentShipping: contentShipping
                })
            }

            render() {
                return (
                    <div className="segment--content">
                        <div className="segment--content__columns">
                            <div className="segment--content__column">  
                                {this.state.contentShipping && this.state.contentShipping.title && (
                                    <div 
                                        className="content__header"
                                        dangerouslySetInnerHTML={{ __html: this.state.contentShipping.title.rendered}}
                                    />
                                    )
                                }
                                {this.state.contentShipping && this.state.contentShipping.content &&
                                    <div 
                                        className="content__text" 
                                        dangerouslySetInnerHTML={{ __html: this.state.contentShipping.content.rendered}}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                );
            }
        }
    )
)
                    
export default CartShipping;

