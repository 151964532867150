import { makeObservable, observable, computed, action, decorate } from "mobx";
import * as mobx from 'mobx';
import { runInAction } from "mobx";

import * as orders from '../communicator/shop/orders';

class StoreOrders {
    orderFb = {}
  
    constructor(storeRoot) {
        this.storeRoot = storeRoot;

        makeObservable(this, {
            orderFb: observable
        })
    }

    async submitOrder(cartId) {
        let result;
        try {
            const returnData = await orders.submitOrder(cartId);
            runInAction(() => { 
                if (returnData.data.fb === 'success') {
                    this.orderFb = returnData.data;
                    result = 'success';
                } 
                return result;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return result;
    }   
    
}

export default StoreOrders;