import * as apifetch from '../fetch.js';

export async function getCart(id) {
    const urlEndpoint = 'carts/' + id;

    return apifetch.kkFetch(urlEndpoint, 'GET');
}

export async function addToCart(accountId, product) {
    const urlEndpoint = 'carts/' + accountId + '/add';

    const postData = product;

    console.log('SbC', accountId, product)

    return apifetch.kkFetch(urlEndpoint, 'POST', postData);
}

export async function updateCart(accountId, product) {
    const urlEndpoint = 'carts/' + accountId + '/update';

    const postData = product;

    return apifetch.kkFetch(urlEndpoint, 'PUT', postData);
}

export async function deleteFromCart(cartId, product) {
    const urlEndpoint = 'carts/' + cartId + '/delete';

    const postData = product;

    return apifetch.kkFetch(urlEndpoint, 'DELETE', postData);
}

export async function getShipping(country) {
    const urlEndpoint = 'shipping/' + country;

    return apifetch.kkFetch(urlEndpoint, 'GET');
}

export async function saveShipping(cartId, shipping) {
    const urlEndpoint = 'carts/' + cartId + '/shipping';

    const postData = {
        shipping: shipping
    };

    return apifetch.kkFetch(urlEndpoint, 'POST', postData);
}

export async function saveVAT(cartId, vat) {
    const urlEndpoint = 'carts/' + cartId + '/vat';

    const postData = {
        vat: vat
    };

    return apifetch.kkFetch(urlEndpoint, 'POST', postData);
}

export async function saveTotals(cartId, subtotal, total) {
    const urlEndpoint = 'carts/' + cartId + '/totals';

    const postData = {
        subtotal: subtotal,
        total: total
    };

    return apifetch.kkFetch(urlEndpoint, 'POST', postData);
}