import { makeAutoObservable, computed, action, decorate } from "mobx";
import * as mobx from 'mobx';
import { runInAction } from "mobx";

import * as settings from '../communicator/settings';

class StoreGeneral {
    listSettings = []
    listPackaging = [];
    
    constructor() {
        this.getSettings();
        this.getPackaging();
    }
    
    getSettings = async() => {
        try {
            const returnData = await settings.getSettings();
            runInAction(() => {  
                if (returnData && returnData.length !== 0) {
                    const settings = returnData.data;
                    settings.map(setting => {
                        this.listSettings[setting.settingName] = setting.settingValue;
                    })
                } 
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

    getCountries = async() => {

    }

    async getPackaging() {
        let listPackaging = [];
        try {
            const returnData = await settings.getPackaging();
            runInAction(() => {  
                if (returnData && returnData.length !== 0) {
                    this.listPackaging = returnData.data;
                    listPackaging = returnData.data;
                } 
                return listPackaging;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return listPackaging;
    }
}

export default StoreGeneral;