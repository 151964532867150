import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router-dom';

import Template from '../../templates/template';
import ListProducts from '../../components/shop/list-products';
import IndexProducts from '../../components/shop/index-products';

const Products = inject("stores") (
    observer (
        class Products extends Component {

            constructor(props) {
                super(props);
                this.storeAuth = this.props.stores.storeAuth;
                this.storeCarts = this.props.stores.storeCarts;
                this.storeContent = this.props.stores.storeContent;
                this.storeProducts = this.props.stores.storeProducts;

                this.state = {
                    content: [],
                    products: []
                }
            }

            componentDidMount = async() => {
                let idxs = [16, 17, 18];

                let content = [];

                const requests = await Promise.all(idxs.map(async(key, i) => {
                    content[i] = await this.storeContent.getContentBlock('pageblocks', key);
                }))
                const products = await this.storeProducts.getProducts();

                const cart = await this.getCart();

                this.setState({
                    content: content,
                    products: products
                })

                window.scroll(0,0);
            }

            componentDidUpdate = async (prevProps) => {
            }

            getCart = async() => {
                const id = this.storeAuth.getCartId();
                const cart = await this.storeCarts.getCart(id);

                return cart;
            }

            gotoPage = (url) => {
                this.props.history.push(url);
            }

            render() {
                return (
                    <Template
                        title = {"Mijn producten"}
                    >
                        <div className="page-content page-content--products">
                            {/* <div
                                 className="segment--content segment--content__index--products"
                            >
                                <IndexProducts
                                    content = {this.state.content}
                                    products = {this.state.products}
                                />
                            </div> */}

                            <div className="segment--content segment--content__products-header">
                                <div className="products-header__content">
                                    {this.state.content[0] && this.state.content[0].title && (
                                        <div 
                                            className="list-products__header"
                                            dangerouslySetInnerHTML={{ __html: this.state.content[0].title.rendered}}
                                        />
                                        )
                                    }
                                    {this.state.content[0] && this.state.content[0].content &&
                                        <div 
                                            className="list-products__intro" 
                                            dangerouslySetInnerHTML={{ __html: this.state.content[0].content.rendered}}
                                        />
                                    }
                                </div>
                            </div>
                            
                            <div className="segment--content segment--content-products">
                                <div className="list-products">
                                    <div className="list-products__group">
                                        <ListProducts
                                            type = {'mix'}
                                            products = {this.state.products}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="segment--content segment--content__products-header">
                                <div className="products-header__content">
                                    {this.state.content[1] && this.state.content[1].title && (
                                        <div 
                                            className="list-products__header"
                                            dangerouslySetInnerHTML={{ __html: this.state.content[1].title.rendered}}
                                        />
                                        )
                                    }
                                    {this.state.content[1] && this.state.content[1].content &&
                                        <div 
                                            className="list-products__intro" 
                                            dangerouslySetInnerHTML={{ __html: this.state.content[1].content.rendered}}
                                        />
                                    }
                                </div>
                             </div>

                            <div className="segment--content segment--content-products">
                                <div className="list-products">
                                    <div className="list-products__group">
                                        <ListProducts
                                            type = {'bullets'}
                                            products = {this.state.products}
                                        />
                                        <div className="content__alert">
                                            <p><strong>Let op</strong><br/>Op kruiden gebaseerd lekkers moet u nooit te veel en niet continu geven. Wissel af en geef het alleen als u vindt dat uw hond iets extra's kan gebruiken.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="segment--content segment--content__products-header">
                                <div className="products-header__content">
                                    {this.state.content[2] && this.state.content[2].title && (
                                        <div 
                                            className="list-products__header"
                                            dangerouslySetInnerHTML={{ __html: this.state.content[2].title.rendered}}
                                        />
                                        )
                                    }
                                    {this.state.content[2] && this.state.content[2].content &&
                                        <div 
                                            className="list-products__intro" 
                                            dangerouslySetInnerHTML={{ __html: this.state.content[2].content.rendered}}
                                        />
                                    }
                                </div>
                            </div>

                            <div className="segment--content segment--content-products">
                                <div className="list-products">
                                    <div className="list-products__group">
                                        <ListProducts
                                            type = {'meds'}
                                            products = {this.state.products}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="product__action-buttons action-button--cta">
                                <button 
                                    className="btn btn--primary --link"
                                    onClick={() => this.gotoPage('/cart')}
                                >
                                    Ga naar uw winkelwagen
                                </button>
                            </div>
                        </div>
                    </Template>
                )
            }
        }
    )
)

export default Products;

