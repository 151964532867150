import * as apifetch from './fetch.js';

export async function getJsonProducts() {
    const urlEndpoint = 'products/json';

    return apifetch.kkFetch(urlEndpoint, 'GET');
}

export async function getProducts() {
    const urlEndpoint = 'products/db';

    return apifetch.kkFetch(urlEndpoint, 'GET');
}
