import * as apifetch from '../fetch.js';

export async function createGuestId() {
    const urlEndpoint = 'accounts/new';

    const postData = {
        type: 'guest'
    }
    return apifetch.kkFetch(urlEndpoint, 'POST', postData);
}

export async function getAuth() {
    //const urlAPI = process.env.REACT_APP_URL_API_SHOP;
    const urlAPI = 'https://www.cereza.nl/api/shop/oc';
    const urlEndpoint = urlAPI + '/ocGetAuth.php';

    try {
        let apiStr = urlEndpoint;

        let response = await fetch(apiStr, {
            method: 'GET'
        });
        let responseJson = await response.json();
        if (responseJson !== null) {
            
            let apiAuth;
            if ('error' in responseJson) {
                apiAuth =  responseJson.error;
            } else {
                apiAuth =  responseJson;
            }
            return apiAuth;
        }
    } catch (error) {
        console.log('SbC fetch error: ', error);
    }
}


