import React, { Component } from 'react';
import { runInAction } from "mobx";
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import FormPet from './form-pet';

const FormPets = inject("stores") (
    observer (
        class FormPets extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeAuth = this.props.stores.storeAuth;
                this.storeUi = this.props.stores.storeUi;
                this.storeUsers = this.props.stores.storeUsers;
                this.storeForms = this.props.stores.storeForms;

                this.state = {
                    pets: []
                }
            }

            componentDidMount = async() => {
                let userId;
                
                if (this.storeAuth.user.isLoggedin) {
                    userId = this.storeAuth.user.accountId;
                } else {
                    (async() => await this.storeAuth.getGuestId())();
                    userId = this.storeAuth.user.guestId;
                }

                if (userId !== 0) {
                    const pets = await this.storeUsers.getPets(userId);

                    if (pets) {
                        this.setState({
                            pets: pets
                        })
                    }
                }
            } 

            componentDidUpdate = async(prevProps) => {
            }

            addPetFromDb = (line) => {
                const updatedControls = {
                    ...this.state.formControls
                };

                let updatedFormElement = {
                    ...updatedControls['pets']
                };
                
                let pet = [{
                    pet: {
                        value: line.id,
                        placeholder: i18n.t("forms.placeholder.sku"),
                        validationRules: {
                            isRequired: true
                        },
                    },
                    petName: {
                        value: line.category,
                        placeholder: i18n.t("forms.placeholder.product"),
                        validationRules: {
                            isRequired: true
                        }
                    }
                }];

                let arr = [];
                  
                Object.keys(updatedFormElement).map((key, i) => {
                    arr.push(updatedFormElement[key]);
                })
                updatedFormElement = arr.concat(pet);
                updatedControls['pets'] = updatedFormElement;

                this.setState({
                    formControls: updatedControls
                })
            }

            addPet = async(e) => {            
                let pet = {
                    id: 0,
                    animalType: "",
                    name: ""
                }

                let pets = this.state.pets;    
                pets.push(pet);
                
                this.setState({
                    pets: pets
                })
            }

            getPets = async() => {
                let userId;

                if (this.storeAuth.user.isLoggedin) {
                    userId = this.storeAuth.user.accountId;
                } else {
                    (async() => await this.storeAuth.getGuestId())();
                    userId = this.storeAuth.user.guestId;
                }

                if (userId !== 0) {
                    let pets = await this.storeUsers.getPets(userId);
   
                    this.setState({
                        pets: pets === null ? [] : pets
                    })
                }
            }

            render() {
                return (
                    <React.Fragment>
                    {this.state.pets && this.state.pets.length >= 0 &&
                        <div className="segment--content__columns form">
                            <div className="segment--content__column">
                                <div className="form-column">
                                    <label className={"form-label"}>
                                        {i18n.t("forms.label.pet")}
                                    </label>
                                </div>
                            </div>
                            <div className="segment--content__column">
                                <div className="form-column">
                                    <label className={"form-label"}>
                                        {i18n.t("forms.label.petName")}
                                    </label>
                                </div>
                            </div>
                        </div>
                    }

                    <div className="segment--content__columns --table">
                        {this.state.pets && this.state.pets.map((pet, i) => (
                            <div className="segment--content__column"> 
                                <FormPet
                                    pet = {pet}
                                    getPets = {this.getPets}
                                />
                            </div>
                        ))}
                    </div>
                    <div className="segment--content__columns">
                        <div className="segment--content__column">
                            <span
                                className = "--link"
                                onClick = {() => this.addPet()}
                            >
                                + Voeg huisdier toe
                            </span>
                        </div>
                    </div>
                    </React.Fragment>
                )
            }
        }
    )
)

export default withTranslation()(FormPets);
