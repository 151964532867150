import { makeAutoObservable, computed, action, decorate } from "mobx";
import * as mobx from 'mobx';
import { runInAction } from "mobx";

import * as content from '../communicator/content';
import * as pages from '../communicator/pages';
import * as menus from '../communicator/menus';

class StoreContent {
    app_interface = { 
        lang: '',
        lang_id: 0
    }  
    
    content_pages = [];
    current_page = {};

    constructor() {
        this.getContentPages();
        //this.getMenus();
    }

    async getBackgrounds() {
        let backgrounds = [];
        try {
            const returnData = await content.getBackgrounds();
            runInAction(() => {  
                if (returnData && returnData.length !== 0) {
                    backgrounds = returnData.data;
                    return backgrounds;
                } 
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return backgrounds;
    }

    async getContentBlock(section, id) {
        let data = {};

        try {
            const returnData = await content.getContentBlock(section, id);
            runInAction(() => {  
                if (returnData && returnData.length !== 0) {
                    data = returnData;
                } 
                return data;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return data;
    }

    async getContentPage(id) {
        let content = {};
        try {
            const returnData = await pages.getContentPage(id);
            runInAction(() => {  
                if (returnData && returnData.length !== 0) {
                    content = returnData;
                    this.current_page = returnData;
                } 
                return content;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return content;
    }

    async getContentDialog(id) {
        let content = {};
        try {
            const returnData = await pages.getContentPage(id);
            runInAction(() => {  
                if (returnData && returnData.length !== 0) {
                    content = returnData;
                } 
                return content;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return content;
    }

    async getContentPages() {
        let content = {};
        try {
            const returnData = await pages.getContentPages();
            runInAction(() => {  
                if (returnData && returnData.length !== 0) {
                    content = returnData;
                    this.content_pages = returnData;
                } 
                return content;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return content;
    }

    async getCustomFields() {
        let content = {};
        try {
            const returnData = await pages.getCustomFields();
            runInAction(() => {  
                if (returnData && returnData.length !== 0) {
                    console.log('SbC api return custom fields:', returnData)
                } 
                return content;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return content;
    }

    async getMenus() {
        let content = {};
        try {
            const returnData = await menus.getMenus();
            runInAction(() => {  
                if (returnData && returnData.length !== 0) {
                    //content = returnData;
                    //this.current_page = returnData;
                } 
                return content;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return content;
    }

    async getMenu(id) {
        let content = {};
        try {
            const returnData = await menus.getMenu(id);
            runInAction(() => {  
                if (returnData && returnData.length !== 0) {
                    content = returnData;
                } 
                return content;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error";
                console.log('SbC error api return menu')
            })
        }
        return content;
    }
}

export default StoreContent;