import React, { Component } from 'react';
import { runInAction } from "mobx";
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import RowText from '../form-rows/row-text';

const FormPets = inject("stores") (
    observer (
        class FormPets extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeAuth = this.props.stores.storeAuth;
                this.storeUi = this.props.stores.storeUi;
                this.storeUsers = this.props.stores.storeUsers;
                this.storeForms = this.props.stores.storeForms;

                this.state = {
                    formIsValid: false,
                    formControls: { 
                        pet: {
                            type: "text",
                            value: this.props.pet.animalType,
                            validationRules: {
                                isRequired: false
                            },
                            valid: true
                        },
                        petName: {
                            type: "text",
                            value: this.props.pet.name,
                            validationRules: {
                                isRequired: false
                            },
                            valid: true
                        }
                    }
                }
            }

            componentDidMount = async() => {
                let userId;
                console.log('SbC init pet', this.props.pet)
            } 

            componentDidUpdate = async(prevProps) => {
            }

            addPetFromDb = (line) => {
                const updatedControls = {
                    ...this.state.formControls
                };

                let updatedFormElement = {
                    ...updatedControls['pets']
                };
                
                let pet = [{
                    pet: {
                        value: line.id,
                        placeholder: i18n.t("forms.placeholder.sku"),
                        validationRules: {
                            isRequired: true
                        },
                    },
                    petName: {
                        value: line.category,
                        placeholder: i18n.t("forms.placeholder.product"),
                        validationRules: {
                            isRequired: true
                        }
                    }
                }];

                let arr = [];
                  
                Object.keys(updatedFormElement).map((key, i) => {
                    arr.push(updatedFormElement[key]);
                })
                updatedFormElement = arr.concat(pet);
                updatedControls['pets'] = updatedFormElement;

                this.setState({
                    formControls: updatedControls
                })
            }          

            initForm = async() => {
                let name = '';
                let value = '';

                name = 'pet';
                value =  this.state.pets.animalType;
                await this.initRow(name, value);

                name = 'petName';
                value =  this.state.pets.name;
                await this.initRow(name, value);
            }

            initRow = async(name, value) => {
                const valid = true;
                const updatedControls = this.storeForms.validateRow(this.state.formControls, name, value, valid);

                this.setState({
                    formControls: updatedControls
                })
            }

            validateRow = (name, value, valid) => {        
                const updatedControls = this.storeForms.validateRow(this.state.formControls, name, value, valid);

                this.setState({
                    formControls: updatedControls
                }, () => this.validateForm()); 
            }

            validateForm = () => {
                const formIsValid = this.storeForms.validateForm(this.state.formControls);

                this.setState({
                    formIsValid: formIsValid,
                    feedback: false,
                    btn_class: 
                    formIsValid ? '--active' : '--disabled'
                }, () => this.savePet());
            }

            savePet = async() => {
                const data = this.state.formControls;
                const petId = this.props.pet.id;

                if (this.storeAuth.user.isLoggedin) {
                    const pet = await this.storeUsers.savePet('account', this.storeAuth.user.accountId, petId, data);
                } else {
                    const pet = await this.storeUsers.savePet('guest', this.storeAuth.user.guestId, petId, data);
                }

                this.props.getPets();
            }

            handleDelete = async() => {
                const petId = this.props.pet.id;
                const pet = await this.storeUsers.deletePet(petId);

                this.props.getPets();
            }

            render() {
                return (
                    <form className="form form-row--pet">
                        {Object.keys(this.state.formControls).map((key, i) => (
                            <RowText
                                type = {this.state.formControls[key].type}
                                name = {key}
                                value = {this.state.formControls[key].value}
                                validationRules = {this.state.formControls[key].validationRules}
                                validationMsg = {this.state.formControls[key].validationMsg}
                                onValidate = {this.validateRow}
                                disable = {this.state.formControls[key].disable}
                            />
                        ))}
                        <span 
                            className="icon icon--delete --link"
                            onClick = {() => this.handleDelete()}
                        />
                    </form>
                )
            }
        }
    )
)

export default withTranslation()(FormPets);
