import * as apifetch from './fetch.js';

export async function getSettings() {
    const urlEndpoint = 'settings/list';

    return apifetch.kkFetch(urlEndpoint, 'GET');
}

export async function getPackaging() {
    const urlEndpoint = 'settings/packaging';

    return apifetch.kkFetch(urlEndpoint, 'GET');
}

export async function getShipperCosts($weight) {
    const urlEndpoint = 'settings/shippercosts/' + $weight;

    return apifetch.kkFetch(urlEndpoint, 'GET');
}
