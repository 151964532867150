import { makeObservable, observable, computed, action, decorate } from "mobx";
import * as mobx from 'mobx';
import { runInAction } from "mobx";

import * as carts from '../communicator/shop/carts';

class StoreCarts {

    cart = [];
    cartAmount = 0;
    
    constructor(storeRoot) {
        this.storeAuth = storeRoot.storeAuth;
        
        makeObservable(this, {
            cart: observable,
            cartAmount: observable
        })
    }

    async getCart(id) {
        let cart;
        try {
            const returnData = await carts.getCart(id);
            runInAction(() => {  
                if (returnData && returnData.length !== 0) {
                    this.cart = returnData.data;
                    cart = returnData.data;
                } 
                return cart;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return cart;
    }

    async getCartAmount(id) {
        let cart;
        let total = 0;
        try {
            const returnData = await carts.getCart(id);
            runInAction(() => {  
                if (returnData && returnData.length !== 0) {
                    cart = returnData.data;
                    cart.map((item) => {
                        total = total + parseInt(item.amount);
                    });
                    this.cartAmount = total;
                } 
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

    setCartAmount(amount) {
        this.cartAmount = amount;
    }

    addToCart = async(type, id, product, amount) => {
        let productLine = {
            id: id,
            guestId: this.storeAuth.user.guestId,
            accountId: this.storeAuth.user.accountId,
            type: this.storeAuth.userType,
            amount: amount,
            sku: product.sku,
            displayTitle: product.displayTitle,
            displayValue: product.displayValue,
            price: product.price,
            vat: product.vat,
            discount: 0
        }

        if (this.cart !== null && this.cart.length > 0) {
            const idx = this.cart.findIndex(item => product.sku === item.sku);
            if (idx === -1) {
                //SbC add to cart
                let cart = await this.addCartItem(id, productLine);
            } else {
                //SbC replace amount in cart
                let amount = parseInt(this.cart[idx].amount) + 1;
                let cart = await this.updateCartItem(id, productLine);
            }
        } else {
            let cart = await this.addCartItem(id, productLine);
        }
    }

    async addCartItem(id, product) {
        try {
            const returnData = await carts.addToCart(id, product);
            runInAction(() => {  
                if (returnData && returnData.length !== 0) {
                    this.storeAuth.setCartId(returnData.data[0]);
                } 
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

    async updateCartItem(id, product) {
        try {
            const returnData = await carts.updateCart(id, product);
            runInAction(() => {  
                if (returnData && returnData.length !== 0) {
                    //cart = returnData.data;
                } 
                // cart;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        //return cart;
    }

    async deleteFromCart(cartId, product) {
        const productLine = {
            sku: product.sku
        }
        try {
            const returnData = await carts.deleteFromCart(cartId, productLine);
            runInAction(() => {  
                if (returnData && returnData.length !== 0) {
                } 
                //return cart;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        //return cart;
    }

    saveTotals = async(cartId, subtotal, total) => {
        try {
            const returnData = await carts.saveTotals(cartId, subtotal, total);
            runInAction(() => {  
                if (returnData && returnData.length !== 0) {

                } 
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }
    
}

export default StoreCarts;